import {Partner} from "./Partner";

export class Fund {

  constructor(fundObj) {
    if(typeof fundObj === 'undefined' || fundObj === null){
      fundObj = {};
    }

    this.id          = fundObj.id || null;
    this.description = fundObj.description || null;
    this.amount      = fundObj.amount || null;
    this.currency    = fundObj.currency || 'brl';
    this.type        = fundObj.type || null;
    this.partner     = new Partner();

    switch (fundObj.type) {
    case 'withdraw':
      this.class = 'bg-warning-lighter';
      break;
    case 'comission':
      this.class = 'bg-success-lighter';
      break;
    }

    this.labelType = 'global.type.' + this.type;

    if(typeof fundObj.partner !== "undefined"){
      this.partner = new Partner(fundObj.partner);
    }
  }

  static init(fundObj) {
    return new Fund(fundObj);
  }
}

